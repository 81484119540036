import React, { ChangeEvent, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { AppBar, Button, Card, CardContent, CardHeader, Container, Divider, Grid, LinearProgress, Paper, TextField, Toolbar, Typography } from '@mui/material';
import { DataResponse, getData } from './services/services';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InfoIcon from '@mui/icons-material/Info';
import facts from './assets/car_facts.png'
import equipment from "./assets/car_equipment.png";
import images from "./assets/images.png";
import title from "./assets/title.png";
import payment from "./assets/payment.png";
import priceLocationPreview from "./assets/price_location_preview.png";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AutorenewIcon from '@mui/icons-material/Autorenew';

function App() {
  const [step, setStep] = useState<number>(1);
  const [licensePlate, setlicensePlate] = useState<string>('');
  const [data, setData] = useState<DataResponse>()

  const handleLicensePlate = (e: ChangeEvent<HTMLInputElement>) => {
    setlicensePlate(e.currentTarget.value)
  }

  const handleSubmit = () => {
    getData(licensePlate).then(setData)
  }

  return (
    <Container maxWidth="sm" style={{paddingTop: 24, paddingBottom: 120}}>
      {step === 1 && <>
      
      <Typography variant='h4' paragraph>Car details</Typography>
      <Typography component='p' variant='caption' gutterBottom>Automatically retrieved license plate</Typography>
      <Button className='wireframe' style={{width: '100%'}} variant='contained' endIcon={<DocumentScannerIcon />}>Scan</Button>
      <Typography component='p' variant='caption' gutterBottom style={{marginTop: 16}}>Or type it in</Typography>
      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <TextField placeholder='License plate' value={licensePlate} onChange={handleLicensePlate} size='small' /> 
        </Grid>
        <Grid item>
          <Button onClick={handleSubmit} disabled={licensePlate.length < 2} variant='outlined'>Find details</Button>
        </Grid>
      </Grid>
      <Typography component='p' variant='caption' style={{marginTop: 4}} gutterBottom>
        <InfoIcon color='primary' style={{verticalAlign: '-7px'}} /> Registration number will not show in the ad
      </Typography>

      {!!data && <>
        <img src={facts} className='wireframe' alt='car facts' width={375} style={{marginTop: 24}} />
        <div style={{marginTop: 24}}>
          <Typography variant='h6' paragraph>
            This car is electric!
          </Typography>
          <Card variant='outlined'>
            <CardContent>
                  <Typography variant='body2' gutterBottom><strong>Home charging</strong></Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField label='Speed' size='small' value={data.charging.AC?.charge_value} InputProps={{endAdornment: 'kW/h'}}  InputLabelProps={{shrink: true}} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label='Duration' size='small' value={data.charging.AC?.charging_time! / 60} InputProps={{endAdornment: 'hours'}} InputLabelProps={{shrink: true}} />
                </Grid>
              </Grid>
                <Typography variant='body2' gutterBottom style={{marginTop: 16}}><strong>Highspeed charging</strong></Typography>
                <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField label='Speed' size='small' value={data.charging.DC?.charge_value} InputProps={{endAdornment: 'kW/h'}}  InputLabelProps={{shrink: true}} />
                </Grid>
                <Grid item xs={6}>
                  <TextField label='Duration' size='small' value={(data.charging.DC?.charging_time! / 60).toFixed(1)} InputProps={{endAdornment: 'hours'}} InputLabelProps={{shrink: true}} />
                </Grid>
              </Grid>
              <Typography component='p' variant='caption' style={{marginTop: 16, marginBottom: -8}} gutterBottom>
                <InfoIcon color='primary' style={{verticalAlign: '-7px'}} /> Autogenerated with AI. Feel free to edit.
              </Typography>
            </CardContent>
          </Card>
        </div>
        <img src={equipment} className='wireframe' alt='car facts' width={375} style={{marginTop: 24}} />
      </>}
      </>}
      {step === 2 && <>
        <Typography variant='h4' paragraph>Car details</Typography>
        <img src={images} className='wireframe' alt='car facts' width='100%' style={{marginLeft: -24, marginRight: -24}} />
        <img src={title} className='wireframe' alt='car facts' width={375} style={{marginTop: 24}} />

        <div>
          <Typography variant='h6' paragraph>
            Short description
          </Typography>
          <TextField multiline value={data?.description} fullWidth InputProps={{endAdornment: <Button variant='outlined' startIcon={<AutorenewIcon />} color='inherit' style={{marginTop: 16}}>New suggestion</Button>, style: {flexDirection: 'column', alignItems: 'flex-start'}}} />
          <Typography component='p' variant='caption' style={{marginTop: 8}} gutterBottom>
                <InfoIcon color='primary' style={{verticalAlign: '-7px'}} /> Autogenerated with AI. Feel free to edit.
              </Typography>
        </div>

        <img src={priceLocationPreview} className='wireframe' alt='car facts' width={375} style={{marginTop: 24}} />
      </>}
      {step === 3 && <>
      <Typography variant='h4' paragraph>Summary</Typography>
        <img src={payment} className='wireframe' alt='car facts' width={375} style={{marginTop: 24}} />
      </>}
      <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0, borderTop: '1px solid rgba(217, 217, 217, 1)' }}>
        <LinearProgress variant="determinate" value={100/3*step} />
        <Toolbar>

      <Grid container justifyContent='space-between' spacing={3} style={{marginTop: 1, marginBottom: 24}}>
        <Grid item>
          <Button disabled={step === 1} color='inherit' startIcon={<ChevronLeftIcon />} onClick={() => setStep(prev => prev - 1)}>Back</Button>
        </Grid>
        <Grid item>
          <Button variant='contained' size='large' onClick={() => {if(step === 3) {alert('Yeaaaaaahhh :-)'); return;} setStep(prev => prev + 1)}}>{step === 3 ? 'Publish' : 'Next'}</Button>
        </Grid>
      </Grid>
        </Toolbar>
      </AppBar>
      
    </Container>
  );
}

export default App;
