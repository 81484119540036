import { httpRequestService } from "./requestService";

export interface DataResponse {
    description: string,
    charging: {
        AC?: {
            charge_value: number;
            charging_time: number;
        };
        DC?: {
            charge_value: number;
            charging_time: number;
        };
    }
    }

export const getData = (licensePlate: string) => {
  return httpRequestService.get<DataResponse>(`/api/generate?regnr=${licensePlate}`);
};