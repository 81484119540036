class HttpRequestService {
  private fetchOptions: RequestInit = {
    cache: 'no-store',
    credentials: 'same-origin',
    headers: new Headers({
      'Cache-Control': 'no-cache',
      'Accept': 'application/json',
      Pragma: 'no-cache',
    })
  }

  public async get<T>(url: string): Promise<T> {
    const res = await fetch(url, this.fetchOptions);

    if (res.status === 401) {
      window.location.replace(window.location.origin);
      return Promise.reject();
    }

    if (!res.ok) {
      throw new Error(`${res.status}`);
    }

    const result = await res.json();
    return result;
  }

  public async put(url: string, postobj: object): Promise<boolean> {
    const res = await fetch(
      url,
      {
        ...this.fetchOptions,
        method: 'put',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postobj)
      }
    );

    if (res.status === 401) {
      window.location.replace(window.location.origin);
      return Promise.reject();
    }

    if (!res.ok) {
      throw new Error(`${res.status}`);
    }

    return Promise.resolve(true);
  }

  public async post<T>(url: string, postobj: object): Promise<T> {
    const res = await fetch(
      url,
      {
        ...this.fetchOptions,
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postobj)
      }
    );

    if (res.status === 401) {
      window.location.replace(window.location.origin);
      return Promise.reject();
    }

    if (!res.ok) {
      throw new Error(`${res.status}`);
    }

    return (await res.json() as T);
  }

  public async delete(url: string): Promise<boolean> {
    const res = await fetch(
      url,
      { ...this.fetchOptions, method: 'delete' }
    );

    if (res.status === 401) {
      window.location.replace(window.location.origin);
      return Promise.reject();
    }
    
    if (!res.ok) {
      throw new Error(`${res.status}`);
    }

    return Promise.resolve(true);
  }
}

export const httpRequestService = new HttpRequestService();